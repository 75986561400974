<template>
  <b-field :message="message" :type="status" :class="{ 'is-required': required }">
    <b-checkbox
      :ref="name"
      :name="name"
      :required="required"
      @input="input"
      :data-cy="`input-order-${name}`"
      :true-value="trueValue"
      v-model="model"
      :disabled="disabled"
    >
      <slot />
    </b-checkbox>
  </b-field>
</template>

<script>
import FieldMixin from "@/mixins/FieldMixin"

export default {
  name: "FieldCheckbox",
  mixins: [FieldMixin],
  props: ["trueValue"],
}
</script>

import _ from "lodash"

const storageName = [window.location.hostname, "ataquilla-gtm"].join(".")

export default {
  namespaced: true,
  state: {
    initialized: false,
    // @see EventDetail.vue
    checkoutType: null,
  },
  getters: {
    getCheckoutType: (state) => {
      return state.checkoutType ? state.checkoutType : "ch_corto"
    },
  },
  actions: {
    load({ commit }) {
      commit("loadStorage")
    },
    reset() {
      return this._vm.$gtm.trackEvent({ ecommerce: null, eventCategory: null })
    },
    /* eslint-disable no-unused-vars */
    track({ store }, payload) {
      return this._vm.$gtm.trackEvent(payload)
    },
    saveState({ state }) {
      localStorage.setItem(
        storageName,
        JSON.stringify({
          checkoutType: state.checkoutType,
        })
      )
    },
    setCheckoutType({ dispatch, commit }, checkoutType) {
      commit("setCheckoutType", checkoutType)
      dispatch("saveState")
    },
    // Steps
    async step1({ getters, dispatch }, payload) {
      if (!this._vm.$env.gtm_id || !this._vm.$gtm.enabled()) {
        return
      }
      await dispatch("reset")
      await dispatch("track", {
        event: "checkoutOption",
        ecommerce: {
          checkout_option: {
            actionField: { step: 1, option: "Elegir Sesion" },
          },
        },
      })
      await dispatch("reset")
      await dispatch("track", {
        event: "checkout",
        eventCategory: "Evento",
        eventLabel: getters.getCheckoutType,
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
              option: "Elegir Sesion",
            },
            products: [
              {
                name: payload.event.name,
                id: payload.event.lookup_ref,
                price: payload.session.min_total_price,
                category: "Evento",
                variant: payload.event.place.name,
                quantity: 1,
                dimension1: payload.event.place.municipality,
                dimension2: payload.session.start_date,
                dimension3: payload.session.start_time,
              },
            ],
          },
        },
      })
    },
    async step2({ getters, dispatch }, payload) {
      if (!this._vm.$env.gtm_id || !this._vm.$gtm.enabled()) {
        return
      }
      await dispatch("reset")
      await dispatch("track", {
        event: "checkoutOption",
        ecommerce: {
          checkout_option: {
            actionField: { step: 2, option: "Elegir Zona" },
          },
        },
      })
      await dispatch("reset")
      await dispatch("track", {
        event: "checkout",
        eventCategory: "Evento",
        eventLabel: getters.getCheckoutType,
        ecommerce: {
          checkout: {
            actionField: {
              step: 2,
              option: "Elegir Zona",
            },
            products: [
              {
                name: payload.event.name,
                id: payload.event_lookup_ref,
                price: payload.session.min_total_price,
                category: "Evento",
                variant: payload.event.place.name,
                quantity: 1,
                dimension1: payload.event.place.municipality,
                dimension2: payload.session.start_date,
                dimension3: payload.session.start_time,
                dimension4: payload.zone.name,
                dimension7: "",
                dimension8: "No",
              },
            ],
          },
        },
      })
    },
    async step3({ getters, dispatch, rootState }, payload) {
      if (!this._vm.$env.gtm_id || !this._vm.$gtm.enabled()) {
        return
      }

      let products = []
      let price = 0
      let num_tickets = 0
      let total_price = 0

      // Prepare products array
      rootState.shoppingCart.cart.forEach((line) => {
        let zone = _.find(payload.sessionZones, {
          id: parseInt(line.sessionZoneId),
        })

        if (!payload.session) return
        if (!zone) return
        if (line.concessionId) {
          let concession = _.find(zone.concessions, { id: line.concessionId })
          if (!concession) return
          price = parseFloat(concession.ticket_price)
        } else {
          price = parseFloat(zone.ticket_price)
        }
        total_price += price
        num_tickets += 1
      })

      products.push({
        name: payload.session.event.name,
        id: payload.event_lookup_ref,
        price: parseFloat(total_price / num_tickets).toFixed(2),
        category: "Evento",
        variant: payload.session.event.place.name,
        quantity: num_tickets,
        dimension1: payload.session.event.place.municipality,
        dimension2: payload.session.start_date,
        dimension3: payload.session.start_time,
        dimension5: num_tickets,
        dimension6: "",
        dimension7: "",
        dimension8: "No",
      })

      await dispatch("reset")
      await dispatch("track", {
        event: "checkoutOption",
        ecommerce: {
          checkout_option: {
            actionField: { step: 3, option: "Elegir Butacas" },
          },
        },
      })
      await dispatch("reset")
      await dispatch("track", {
        event: "checkout",
        eventCategory: "Evento",
        eventLabel: getters.getCheckoutType,
        ecommerce: {
          checkout: {
            actionField: {
              step: 3,
              option: "Elegir Butacas",
            },
            products: products,
          },
        },
      })
    },
    async step4({ getters, dispatch }, order) {
      if (!this._vm.$env.gtm_id || !this._vm.$gtm.enabled()) {
        return
      }
      if (order.tickets.length == 0) {
        return
      }

      let products = []
      let coupon = ""

      if (order.discount_coupon) {
        coupon = order.discount_coupon.code
      }

      products.push({
        name: order.event.name,
        id: order.event.lookup_ref,
        price: parseFloat(order.total_amount / order.tickets.length).toFixed(2),
        category: "Evento",
        variant: order.event.place.name,
        quantity: order.tickets.length,
        dimension1: order.event.place.municipality,
        dimension2: order.tickets[0].start_date,
        dimension3: order.tickets[0].start_time,
        dimension5: order.tickets.length,
        dimension6: "",
        dimension7: coupon,
        dimension8: "No",
      })

      await dispatch("reset")
      await dispatch("track", {
        event: "checkoutOption",
        ecommerce: {
          checkout_option: {
            actionField: { step: 4, option: "Datos asistentes y Pago" },
          },
        },
      })
      await dispatch("reset")
      await dispatch("track", {
        event: "checkout",
        eventCategory: "Evento",
        eventLabel: getters.getCheckoutType,
        ecommerce: {
          checkout: {
            actionField: {
              step: 4,
              option: "Datos asistentes y Pago",
            },
            products: products,
          },
        },
      })
    },
  },
  mutations: {
    setCheckoutType(state, checkoutType) {
      state.checkoutType = checkoutType
    },
    loadStorage(state) {
      const storageData = localStorage.getItem(storageName)
      if (!storageData) return
      try {
        let data = JSON.parse(storageData)
        state.checkoutType = data.checkoutType
      } catch (error) {
        return
      }
      state.initialized = true
    },
  },
}

import _ from "lodash"

const storageName = [window.location.hostname, "ataquilla-cart"].join(".")

export default {
  namespaced: true,
  state: {
    cart: [],
    visible: false,
    sessionLookupRef: null,
  },
  getters: {
    numTickets: (state) => {
      return state.cart.length
    },
    getCartLineBySeatId: (state) => (seatId) => {
      return state.cart.find((line) => line.seatId === seatId)
    },
  },
  actions: {
    update(context, args) {
      context.commit(args[0], args[1])
      context.commit("saveState")
    },
  },
  mutations: {
    toggle(state, visible) {
      if (visible != undefined) {
        state.visible = visible
      } else {
        state.visible = !state.visible
      }
    },
    resetCart: (state) => {
      return (state.cart = [])
    },
    removeSeat: (state, seatId) => {
      const cart = _.pull(
        state.cart,
        _.find(state.cart, (x) => x.seatId == seatId)
      )
      return (state.cart = _.compact(cart))
    },
    addSeat: (state, data) => {
      const index = _.findIndex(state.cart, (x) => x.seatId == data.seatId)
      if (index > -1) return
      return state.cart.push(data)
    },
    removeLine: (state, index) => {
      return state.cart.splice(index, 1)
    },
    setConcessionId(state, data) {
      return (state.cart[data.index].concessionId = data.concessionId)
    },
    addLines(state, lines) {
      _.each(lines, (line) => {
        state.cart.push({
          sessionZoneId: line.sessionZoneId,
          seatId: line.seatId,
          concessionId: line.concessionId,
        })
      })
      state.visible = true
    },
    loadState(state, sessionLookupRef) {
      if (localStorage.getItem(storageName)) {
        const data = JSON.parse(localStorage.getItem(storageName))
        if (sessionLookupRef && data.sessionLookupRef != sessionLookupRef) {
          state.cart = []
          state.sessionLookupRef = sessionLookupRef
          localStorage.setItem(
            storageName,
            JSON.stringify({
              sessionLookupRef: state.sessionLookupRef,
              cart: state.cart,
            })
          )
        } else {
          Object.assign(state, {
            sessionLookupRef: data.sessionLookupRef,
            cart: data.cart,
          })
        }
      } else {
        localStorage.setItem(
          storageName,
          JSON.stringify({
            sessionLookupRef: sessionLookupRef,
            cart: [],
          })
        )
      }
    },
    saveState(state) {
      localStorage.setItem(
        storageName,
        JSON.stringify({
          sessionLookupRef: state.sessionLookupRef,
          cart: state.cart,
        })
      )
    },
  },
}

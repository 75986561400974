import _ from "lodash"
import moment from "moment"
import vm from "@/main.ts"

function amount(value) {
  if (isNaN(value)) return ""
  return _.round(value, 2).toFixed(2)
}

export default {
  amount(value) {
    return amount(value, 2)
  },
  currency(value) {
    return `${amount(value, 2)} €`
  },
  uppercase(value) {
    if (!value) {
      return ""
    }
    return value.toString().toUpperCase()
  },
  percentage(value) {
    if (isNaN(value)) return ""
    return `${(parseFloat(value) * 100).toFixed(2)}%`
  },
  percentageRound(value) {
    if (isNaN(value)) return ""
    return `${Math.round(parseFloat(value) * 100)}%`
  },
  formatDate(value) {
    if (!value) return null
    return moment(String(value)).format("DD/MM/YYYY")
  },
  formatDateTime(value) {
    if (!value) return null
    return moment(String(value)).format("DD/MM/YYYY HH:mm:ss")
  },
  formatDateTimeHuman(value) {
    if (!value) return null
    moment.locale(vm.$language.current)
    return moment(String(value)).format("LLLL")
  },
  formatDateHuman(value) {
    if (!value) return null
    moment.locale(vm.$language.current)
    return moment(String(value)).format("LL")
  },
  formatTime(time) {
    if (!time) return null

    const [hours, minutes] = time.split(":")
    return `${hours}:${minutes}`
  },
}

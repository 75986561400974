export default {
  namespaced: true,
  state: {
    message: null,
  },
  actions: {
    success({ state }, text) {
      state.message = { type: "success", text: text, ts: new Date() }
    },
    error({ state }, text) {
      state.message = { type: "danger", text: text, ts: new Date() }
    },
    info({ state }, text) {
      state.message = { type: "info", text: text, ts: new Date() }
    },
  },
}

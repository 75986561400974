import Vue from "vue"
import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration } from "@sentry/integrations"
import svgJs from "./vueSvgPlugin"
import axios from "axios"

Vue.use(svgJs)
Vue.config.productionTip = false

// API

import api from "./services/api"

Object.defineProperty(Vue.prototype, "$api", { value: api })

// Buefy
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
Vue.component("vue-fontawesome", FontAwesomeIcon)

import Buefy from "buefy"
Vue.use(Buefy, {
  defaultIconPack: "fas",
})

// Global components
import FieldCheckbox from "@/components/base/FieldCheckbox.vue"
import FieldInput from "@/components/base/FieldInput.vue"
import FieldDropdown from "@/components/base/FieldDropdown.vue"
import InputNumber from "@/components/base/InputNumber.vue"
import LevelDate from "@/components/base/LevelDate.vue"
import LevelDateCalendar from "@/components/base/LevelDateCalendar.vue"
Vue.component("FieldInput", FieldInput)
Vue.component("FieldCheckbox", FieldCheckbox)
Vue.component("FieldDropdown", FieldDropdown)
Vue.component("InputNumber", InputNumber)
Vue.component("LevelDate", LevelDate)
Vue.component("LevelDateCalendar", LevelDateCalendar)

// Layouts
import PageDefault from "@/layouts/PageDefault.vue"
Vue.component("PageDefault", PageDefault)

// Translations
import GetTextPlugin from "vue-gettext"
import translations from "@/locale/translations.json"
import { availableLanguages, defaultLanguage } from "./globals"

Vue.use(GetTextPlugin, {
  availableLanguages: availableLanguages,
  defaultLanguage: defaultLanguage,
  translations: translations,
  muteLanguages: [defaultLanguage],
  silent: true,
})

// Store
import store from "./services/store"

//GTM
import VueGtm from "vue-gtm"

// App
import router from "./services/router"
import App from "./App.vue"

/**
 * Obtain default global query params
 */
Vue.prototype.$defaultQuery = () => {
  const globalParams = [
    // Channel lookup ref
    "t",
    // Protection token
    "p",
  ].filter((param: string) => param in router.currentRoute.query)

  const cleanedParams: Dictionary<string> = {}

  globalParams.forEach((param: string) => {
    cleanedParams[param] = String(router.currentRoute.query[param])
  })

  return cleanedParams
}

Vue.prototype.$theme = () => {
  if (
    "t" in router.currentRoute.query &&
    router.currentRoute.query.t == "clicentradas"
  ) {
    const themeName = router.currentRoute.query.t
    return {
      image: require("@/assets/img/clicentradas.jpg"),
      name: "Clicentradas",
      href: "https://www.clicentradas.es/",
    }
  }
  return {
    image: require("@/assets/img/logo.png"),
    name: "Ataquilla",
    href: "https://entradas.ataquilla.com/ventaentradas/",
  }
}

const loadEnvConfig = async (vm: Vue) => {
  // Initialize env
  const response = await axios.get("/env.json")

  if (response.status == 200) {
    const env = await response.data
    Vue.prototype.$env = env

    if (env.sentry_dsn) {
      if (env.sentry_dsn) {
        Sentry.init({
          dsn: env.sentry_dsn,
          environment: env.sentry_env || "unknown",
          release: env.package_version,
          integrations: [
            new VueIntegration({ Vue, attachProps: true, logErrors: true }),
          ],
        })
      }
    }

    /* Se inicializa uno u otro GTM pero nunca ambos a la vez */
    if (env.gtm_id) {
      Vue.use(VueGtm, {
        id: env.gtm_id,
        defer: false,
        enabled: true,
        debug: env.gtm_debug,
        loadScript: true,
        vueRouter: router,
        ignoredViews: [],
      })
    } else if (env.gtm2404_id) {
      Vue.use(VueGtm, {
        id: env.gtm2404_id,
        defer: false,
        enabled: true,
        debug: env.gtm_debug,
        loadScript: true,
        vueRouter: router,
        ignoredViews: [],
      })
    }

    // Load i18n Store
    vm.$store.commit("i18n/loadState", { vm })
  }
}

const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {},
})

loadEnvConfig(vm).then(() => {
  vm.$mount("#app")
})

export default vm

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex"
import Toast from "@/mixins/Toast"

export default {
  name: "app",
  mixins: [Toast],
  methods: {
    ...mapActions({
      loadSessions: "order/load",
    }),
  },
  mounted() {
    /**
     * Cargar script de Connectif si está configurado
     * @see src/services/router.ts router.afterEach
     */
    if (this.$env.connectif_script_id) {
      const connectifId = this.$env.connectif_script_id
      const connectifScript = document.createElement("script")
      const connectifText = document.createTextNode(
        `!function(e){function t(){if(!e.querySelector("#__cn_client_script_${connectifId}")){var t=e.createElement("script");t.setAttribute("src","https://cdn.connectif.cloud/eu7/client-script/${connectifId}"),e.body.appendChild(t)}}"complete"===e.readyState||"interactive"===e.readyState?t():e.addEventListener("DOMContentLoaded",t)}(document);`
      )
      connectifScript.setAttribute("async", "")
      connectifScript.setAttribute("id", `__cn_generic_script__${connectifId}`)
      connectifScript.appendChild(connectifText)
      document.body.prepend(connectifScript)
    }

    this.loadSessions()
    this.$store.commit("gtm/loadStorage")
  },
}
</script>

<style lang="sass">
@import './assets/sass/app.sass'
@import './assets/sass/clicentradas.sass'
</style>























































import _ from "lodash"
import { Component, Prop, Watch } from "vue-property-decorator"
import Vue from "vue"
import filters from "@/filters"
import FieldMixin from "@/mixins/FieldMixin"

@Component({
  filters: filters,
  mixins: [FieldMixin],
})
export default class FieldDropdown extends Vue {
  @Prop({ default: "is-small" }) size!: string
  @Prop() items!: Array<Dictionary<any>>
  @Prop() itemValue!: string
  @Prop() itemText!: string
  @Prop() disabled!: boolean
  @Prop() asText!: boolean

  value!: any
  selectedItem: number | string | Dictionary<any> | null = null

  get isReadOnly() {
    return this.disabled || this.items.length == 0
  }

  mounted() {
    this.onValueChange()
  }

  @Watch("items", { deep: true, immediate: true })
  onItemsChange() {
    this.onValueChange()
  }

  @Watch("value", { deep: true, immediate: true })
  onValueChange() {
    if (!this.value) {
      this.selectedItem = null
      return
    }
    if (this.itemValue) {
      const selectedItem = _.find(this.items, (x) => x[this.itemValue] == this.value)
      this.selectedItem = selectedItem ? selectedItem : null
      return
    }

    this.selectedItem = this.value
  }
}

<template>
  <b-field
    :label="label"
    :message="message"
    :type="status"
    :class="{ 'is-required': required }"
  >
    <b-input
      :ref="name"
      :name="name"
      :required="required"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      @input="input"
      :data-cy="`input-order-${name}`"
      :type="type || 'text'"
      v-model="model"
    />
  </b-field>
</template>

<script>
import FieldMixin from "@/mixins/FieldMixin"

export default {
  name: "FieldInput",
  mixins: [FieldMixin],
  mounted() {
    if (this.focus) {
      this.$refs[this.name].focus()
    }
  },
}
</script>

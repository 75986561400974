import _ from "lodash"

const storageName = [window.location.hostname, "ataquilla-multipass-cart"].join(".")

export default {
  namespaced: true,
  state: {
    cart: [],
    visible: false,
    cartKey: null,
    numTickets: 0,
  },
  actions: {
    invalidSeatsDetected() {
      // Used just as an event
    },
    update(context, args) {
      context.commit(args[0], args[1])
      const cartKey = args[2]
      context.commit("saveState", cartKey)
    },
  },
  mutations: {
    toggle(state, visible) {
      if (visible != undefined) {
        state.visible = visible
      } else {
        state.visible = !state.visible
      }
    },
    resetCart: (state) => {
      if (state.numTickets == 0 && state.cart.length == 0) {
        return
      }

      state.numTickets = 0
      state.cart = []
    },
    setNumTickets: (state, numTickets) => {
      state.numTickets = numTickets
    },
    removeSeat: (state, seatId) => {
      const cart = _.pull(
        state.cart,
        _.find(state.cart, (x) => x.seat.id == seatId)
      )
      return (state.cart = _.compact(cart))
    },
    addSeat: (state, data) => {
      const index = _.findIndex(state.cart, (x) => x.seat.id == data.seat.id)
      if (index > -1) return
      return state.cart.push(data)
    },
    removeLine: (state, index) => {
      return state.cart.splice(index, 1)
    },
    setMultipassConcessionId(state, data) {
      return (state.cart[data.index].multipassConcessionId = data.multipassConcessionId)
    },
    addLines(state, lines) {
      _.each(lines, (line) => {
        state.cart.push({
          sessionZoneId: line.sessionZoneId,
          seatId: line.seatId,
          multipassConcessionId: line.multipassConcessionId,
        })
      })
      state.visible = true
    },
    loadState(state, cartKey) {
      if (localStorage.getItem(storageName)) {
        const data = JSON.parse(localStorage.getItem(storageName))

        if (cartKey && data.cartKey != cartKey) {
          state.cart = []
          state.cartKey = cartKey
          localStorage.setItem(
            storageName,
            JSON.stringify({
              cart: state.cart,
              visible: state.visible,
              cartKey: state.cartKey,
              numTickets: state.numTickets,
            })
          )
        } else {
          Object.assign(state, {
            cart: data.cart,
            visible: data.visible,
            cartKey: data.cartKey,
            numTickets: data.numTickets,
          })
        }
      } else {
        localStorage.setItem(
          storageName,
          JSON.stringify({
            cart: [],
            visible: false,
            cartKey: cartKey,
            numTickets: 0,
          })
        )
      }
    },
    saveState(state, cartKey) {
      localStorage.setItem(
        storageName,
        JSON.stringify({
          cart: state.cart,
          visible: state.visible,
          cartKey: cartKey,
          numTickets: state.numTickets,
        })
      )
    },
  },
}

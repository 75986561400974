import { mapState } from "vuex"

export default {
  name: "Toast",
  computed: {
    ...mapState({
      message: (state) => state.ui.message,
    }),
  },
  watch: {
    message: {
      handler(message) {
        this.$buefy.toast.open({
          duration: 3000,
          message: message.text,
          position: "is-bottom",
          type: `is-${message.type}`,
        })
      },
      deep: true,
    },
  },
}

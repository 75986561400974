import axios from "axios"
import Qs from "qs"
import Vue from "vue"
import _ from "lodash"
import router from "@/services/router"
import store from "@/services/store"
import vm from "@/main.ts"

axios.defaults.params = {}

// Get query params to send to backend
// Note: Is a psedo query param because appear after `#`
axios.interceptors.request.use(
  function (config) {
    const hash = window.location.hash
    const query = hash.slice(hash.indexOf("?"))
    const urlSearchParams = new URLSearchParams(query)
    const t = urlSearchParams.get("t")
    const p = urlSearchParams.get("p")

    // Channel LR
    if (t) {
      config.params.t = t
    }

    // Protection token
    if (p) {
      config.params.p = p
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default {
  config() {
    return {
      mode: "no-cors",
      responseType: "json",
      baseURL: vm.$env.api_url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        "Accept-Language": vm.$language.current,
      },
      paramsSerializer: function (params) {
        return Qs.stringify(params, { arrayFormat: "repeat" })
      },
    }
  },
  getApiUrl(endpoint) {
    return `${this.config().baseURL}v1/${endpoint}`
  },
  request(endpoint, config = {}) {
    config = Object.assign(this.config(), config)
    config.url = this.getApiUrl(endpoint)

    let token = ""
    if (token) {
      config.headers["Authorization"] = "Token " + token
    }

    const axiosInstance = axios(config)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (!error.response) {
          store.dispatch(
            "ui/error",
            Vue.prototype.$gettext("Se ha perdido la conexión con el servidor")
          )
        } else if (error.response.data.redirect_url) {
          window.location.href = error.response.data.redirect_url
        } else if (_.includes([500], error.response.status)) {
          store.dispatch("ui/error", Vue.prototype.$gettext("Error inesperado"))
          throw error
        } else if (error.response.status == 404) {
          router.push("/404")
          throw error
        } else {
          throw error
        }
      })

    return axiosInstance
  },

  getEventSimple(lookup_ref) {
    return this.request(`events/${lookup_ref}/simple/`, { method: "get" })
  },

  getEvent(lookup_ref) {
    return this.request(`events/${lookup_ref}/`, { method: "get" })
  },

  getCalendarEvent(lookup_ref, payload) {
    return this.request(`events/${lookup_ref}/calendar/`, {
      method: "get",
      params: payload,
    })
  },

  getEventSameDaySessions(lookup_ref, session_lookup_ref) {
    return this.request(`events/${lookup_ref}/same-day-sessions/`, {
      method: "get",
      params: { session_lookup_ref },
    })
  },

  getSession(lookup_ref) {
    return this.request(`sessions/${lookup_ref}/`, { method: "get" })
  },

  getPaginatedSessions(event_lookup_ref, offset) {
    return this.request(`sessions/`, {
      method: "get",
      params: {
        event__lookup_ref: event_lookup_ref,
        offset: offset || 0,
        sortBy: "start_datetime",
      },
    })
  },

  getZone(id) {
    return this.request(`zones/${id}/`, { method: "get" })
  },

  createOrder(data) {
    return this.request("orders/", { method: "post", data: data })
  },

  getOrder(lookup_ref) {
    return this.request(`orders/${lookup_ref}/`, { method: "get" })
  },
  getOrderOk(lookup_ref) {
    return this.request(`orders/${lookup_ref}/ok/`, { method: "get" })
  },
  abandonOrder(lookup_ref, data) {
    return this.request(`orders/${lookup_ref}/abandon/`, {
      method: "post",
      data: data,
    })
  },
  confirmOrder(lookup_ref, data) {
    return this.request(`orders/${lookup_ref}/confirm/`, {
      method: "post",
      data: data,
    })
  },
  confirmAndCompleteFreeOrder(lookup_ref, data) {
    return this.request(`orders/${lookup_ref}/confirm-and-complete/`, {
      method: "post",
      data: data,
    })
  },

  printOrderTickets(lookup_ref, token) {
    return this.request(`orders/${lookup_ref}/print_tickets/`, {
      method: "options",
    }).then(() => {
      if (token) {
        return this.getApiUrl(`orders/${lookup_ref}/print_tickets/?token=${token}`)
      }
      return this.getApiUrl(`orders/${lookup_ref}/print_tickets/`)
    })
  },

  subdivisions() {
    return this.request("subdivisions/", { method: "get" })
  },

  applyDiscountCode(lookup_ref, data) {
    const url = `orders/${lookup_ref}/apply-discount-code/`
    return this.request(url, { method: "post", data: data })
  },

  /** Multipass URLs */
  getMultipassConfig(lookup_ref) {
    return this.request(`multipass/multipass-configs/${lookup_ref}/`, { method: "get" })
  },

  getMultipassConfigCartZones(lookup_ref, included_zones_ids) {
    return this.request(`multipass/multipass-configs/${lookup_ref}/cart-zones/`, {
      method: "get",
      params: {
        included_zones_ids,
      },
    })
  },

  getMultipassSession(id) {
    return this.request(`multipass/multipass-sessions/${id}/`, { method: "get" })
  },

  getMultipassZone(id, multipassConfigLookupRef, multipassSessionId) {
    return this.request(`multipass/multipass-zones/${id}/`, {
      method: "get",
      params: {
        multipass_config_lookup_ref: multipassConfigLookupRef,
        multipass_session_id: multipassSessionId,
      },
    })
  },

  multipassPreflightOrder(data) {
    return this.request("multipass/multipass-preflight-order/", {
      method: "post",
      data: data,
    })
  },

  createMultipassOrder(data) {
    return this.request("multipass/multipass-orders/", { method: "post", data: data })
  },

  getMultipassOrder(lookup_ref) {
    return this.request(`multipass/multipass-orders/${lookup_ref}/`, { method: "get" })
  },

  getReservedMultipassOrder(lookup_ref) {
    return this.request(`multipass/multipass-orders/${lookup_ref}/reserved/`, {
      method: "get",
    })
  },

  confirmMultipassOrder(lookup_ref, data) {
    return this.request(`multipass/multipass-orders/${lookup_ref}/confirm/`, {
      method: "post",
      data: data,
    })
  },

  multipassAbandonOrder(lookup_ref) {
    return this.request(`multipass/multipass-orders/${lookup_ref}/abandon/`, {
      method: "post",
    })
  },

  updateMultipassOrder(lookup_ref, data) {
    return this.request(`multipass/multipass-orders/${lookup_ref}/update/`, {
      method: "post",
      data: data,
    })
  },

  /** Search Order URL */
  searchOrder(data) {
    return this.request(`orders/search_lookup_ref/`, {
      method: "post",
      data: data,
    })
  },
}

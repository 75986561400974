<template>
  <div class="level-date-calendar">
    <span class="day">{{ day }}</span>
    <div class="container-month-year">
      <span class="month">{{ month }}</span>
      <span class="year">{{ year }}</span>
    </div>
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "LevelDateCalendar",
  props: ["date"],
  computed: {
    year() {
      return this.localeDate ? this.localeDate.format("YYYY") : null
    },
    month() {
      return this.localeDate ? this.localeDate.format("MMM") : null
    },
    day() {
      return this.localeDate ? this.localeDate.format("D") : null
    },
    localeDate() {
      if (!this.date) return null
      return moment(String(this.date)).locale(this.$language.current)
    },
  },
}
</script>
<style lang="sass">
@import '@/assets/sass/_variables.sass'
@import '@/assets/sass/_responsive.sass'

.level-date-calendar
  text-align: center
  .year
    font-size: 14px
    font-weight: 500
    margin: 0
  .month
    font-size: 14px
    margin-right: 5px
    font-weight: 500
  .day
    font-size: 19px
    white-space: nowrap
    margin-right: 5px

@media screen and (max-width: 769px - 1px)
  .level-date-calendar
    display: flex
    align-items: center
    .day
      font-size: 17px
    .container-month-year
      display: flex
      flex-direction: column
      font-size: 12px
      line-height: 15px
</style>

import Vue from "vue"
import Router, { Route } from "vue-router"

Vue.use(Router)

const router = new Router({
  linkActiveClass: "is-active",
  linkExactActiveClass: "is-active",
  routes: [
    {
      path: "/order-recovery",
      name: "order-recovery",
      component: () => import("@/pages/OrderRecovery.vue"),
      meta: {
        title: (route: Route) => {
          return "Consulta operación"
        },
      },
    },
    {
      path: "/event/:event_lookup_ref",
      name: "event",
      component: () => import("@/pages/Event.vue"),
      meta: {
        title: (route: Route) => {
          return `Evento ${route.params.event_lookup_ref}`
        },
      },
    },
    {
      path: "/event/:event_lookup_ref/session/:session_lookup_ref",
      name: "session",
      component: () => import("@/pages/Session.vue"),
      meta: {
        title: () => {
          return `Selección de zonas`
        },
      },
    },
    {
      path: "/event/:event_lookup_ref/session/:session_lookup_ref/zone/:zone_id",
      name: "zone",
      component: () => import("@/pages/Zone.vue"),
      meta: {
        title: () => {
          return `Selección tipo de entradas`
        },
      },
    },
    {
      path: "/order/:lookup_ref/confirm",
      name: "order-confirm",
      component: () => import("@/pages/OrderConfirm.vue"),
      meta: {
        title: (route: Route) => {
          return `Pedido ${route.params.lookup_ref}`
        },
      },
    },
    {
      path: "/order/:lookup_ref/expired",
      name: "order-expired",
      component: () => import("@/pages/OrderExpired.vue"),
      meta: {
        title: (route: Route) => {
          return `Pedido ${route.params.lookup_ref} caducado`
        },
      },
    },
    {
      path: "/order/:lookup_ref/ok",
      name: "order-success",
      component: () => import("@/pages/OrderSuccess.vue"),
      meta: {
        title: (route: Route) => {
          return `Pedido ${route.params.lookup_ref} realizado con éxito`
        },
      },
    },
    {
      path: "/order/:lookup_ref/error",
      name: "order-error",
      component: () => import("@/pages/OrderError.vue"),
      meta: {
        title: (route: Route) => {
          return `Pedido ${route.params.lookup_ref} erróneo`
        },
      },
    },
    /** Multipass routes */
    {
      path: "/multipass/:lookupRef",
      component: () => import("@/pages/multipass/MultipassBase.vue"),
      children: [
        {
          path: "",
          name: "multipass-detail",
          component: () => import("@/pages/multipass/MultipassDetail.vue"),
          meta: {
            title: (route: Route) => {
              return `Abono ${route.params.lookupRef}`
            },
          },
        },
        {
          path: "session/:sessionId",
          name: "multipass-session",
          component: () => import("@/pages/multipass/MultipassSession.vue"),
          children: [
            {
              path: "zone/:zoneId",
              name: "multipass-zone",
              component: () => import("@/pages/multipass/MultipassZone.vue"),
              meta: {
                title: (route: Route) => {
                  return `Zona ${route.params.zoneId} de abono ${route.params.lookupRef}`
                },
              },
            },
          ],
          meta: {
            title: (route: Route) => {
              return `Sesión ${route.params.sessionId} de abono ${route.params.lookupRef}`
            },
          },
        },
      ],
    },
    /**
     * Multipass order
     */
    {
      path: "/multipass-order/:lookup_ref/confirm",
      name: "multipass-order-confirm",
      component: () => import("@/pages/multipass/MultipassOrderConfirm.vue"),
      meta: {
        title: (route: Route) => {
          return `Pedido ${route.params.lookup_ref}`
        },
      },
    },
    {
      path: "/multipass-order/:lookup_ref/reserved",
      name: "multipass-order-reserved",
      component: () => import("@/pages/multipass/MultipassOrderReserved.vue"),
      meta: {
        title: (route: Route) => {
          return `Pedido ${route.params.lookup_ref}`
        },
      },
    },
    {
      path: "/multipass-order/:lookup_ref/ok",
      name: "multipass-order-success",
      component: () => import("@/pages/multipass/MultipassOrderSuccess.vue"),
      meta: {
        title: (route: Route) => {
          return `Pedido ${route.params.lookup_ref} realizado con éxito`
        },
      },
    },
    {
      path: "/multipass-order/:lookup_ref/error",
      name: "multipass-order-error",
      component: () => import("@/pages/multipass/MultipassOrderError.vue"),
      meta: {
        title: (route: Route) => {
          return `Pedido ${route.params.lookup_ref} erróneo`
        },
      },
    },
    {
      path: "/multipass-order/:lookup_ref/expired",
      name: "multipass-order-expired",
      component: () => import("@/components/multipass/MultipassOrderExpired.vue"),
      meta: {
        title: (route: Route) => {
          return `Pedido ${route.params.lookup_ref} caducado`
        },
      },
    },
    /** Error handling routes */
    {
      path: "*",
      component: () => import("@/pages/Status404.vue"),
      meta: {
        title: () => {
          return `Página no encontrada`
        },
      },
    },
  ],
})

router.beforeEach((to: Route, from: Route, next: Function) => {
  const preffix = to.meta.title ? `${to.meta.title(to)} — ` : ""
  const suffix = "Ataquilla.com"
  document.title = `${preffix}${suffix}`
  return next()
})

router.afterEach((to: Route, from: Route) => {
  // Alias para ignorar errores de atributos que no existen
  const window_: any = window

  if (window_.connectif && window_.connectif.managed) {
    window_.initializeConnectif(window_.connectifConfiguration)
  }
})

export default router

import { availableLanguages, defaultLanguage } from "../../globals"

const storageName = [window.location.hostname, "ataquilla-i18n"].join(".")

const saveLanguage = (language) => {
  localStorage.setItem(
    storageName,
    JSON.stringify({
      language: language,
    })
  )
}

export default {
  namespaced: true,
  state: {
    language: null,
  },
  mutations: {
    setLanguage: (state, language) => {
      state.language = language
      saveLanguage(language)
    },
    loadState(state, { vm }) {
      if (localStorage.getItem(storageName)) {
        const data = JSON.parse(localStorage.getItem(storageName))
        if (data.language) {
          Object.assign(state, {
            language: data.language,
          })
        }
      }

      const cleanLanguage = (language) => {
        const isAvailable = availableLanguages[language]
        const isSelectable = (vm.$env.selectable_languages || []).includes(language)
        if (isAvailable && isSelectable) {
          return language
        }
        return null
      }

      const getUrlLanguage = () => {
        // Vue is in hash mode query params are on hash part
        const hash = window.location.hash
        const questionMark = hash.indexOf("?") + 1
        const paramsString = hash.substring(questionMark)
        const params = new URLSearchParams(paramsString)
        return cleanLanguage(params.get("lang"))
      }

      // Set language based on
      // 1. LocalStorage
      // 2. URL lang query param (saved to LocalStorage)
      // 3. Browser language
      // 4. Default language
      const urlLang = getUrlLanguage()
      const navigatorLang = cleanLanguage(navigator.language.replace("-", "_"))
      const storeLang = cleanLanguage(state.language)
      let selectedLanguage = defaultLanguage

      if (storeLang) {
        selectedLanguage = storeLang
      } else if (urlLang) {
        selectedLanguage = urlLang
        saveLanguage(selectedLanguage)
      } else {
        selectedLanguage = navigatorLang || defaultLanguage
      }

      vm.$language.current = selectedLanguage
    },
  },
}

import api from "../api"
const storageName = [window.location.hostname, "ataquilla"].join(".")

export default {
  namespaced: true,
  state: {
    initialized: false,
    sessions: {},
  },
  actions: {
    load({ state, dispatch }) {
      dispatch("loadStorage")
      state.initialized = true
    },
    loadStorage({ state }) {
      const storageData = sessionStorage.getItem(storageName)
      if (!storageData) return
      try {
        let data = JSON.parse(storageData)
        if (typeof data != "object") return
        state.sessions = data
      } catch (error) {
        return
      }
    },
    getEventOrder: ({ state }, eventRef) => {
      return state.sessions[eventRef]
    },
    resetEvent: ({ state }, eventRef) => {
      delete state.sessions[eventRef]
      return sessionStorage.setItem(storageName, JSON.stringify(state.sessions))
    },
    abandon: ({ state, dispatch }, eventRef) => {
      return api.abandonOrder(state.sessions[eventRef]).finally(() => {
        dispatch("resetEvent", eventRef)
      })
    },
  },
  mutations: {
    update(state, data) {
      state.sessions = data
      sessionStorage.setItem(storageName, JSON.stringify(state.sessions))
    },
  },
}

import api from "../api"

export default {
  namespaced: true,
  state: {
    initialized: false,
  },
  actions: {
    load({ state }) {
      state.initialized = true
    },
    abandon: (context, multipassConfigLookupRef) => {
      return api.multipassAbandonOrder(multipassConfigLookupRef).finally(() => {})
    },
  },
}

<template>
  <div class="level-date">
    <span class="month">{{ month }}</span>
    <span class="day">{{ day }}</span>
    <span class="year">{{ year }}</span>
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "LevelDate",
  props: ["date"],
  computed: {
    year() {
      return this.localeDate ? this.localeDate.format("YYYY") : null
    },
    month() {
      return this.localeDate ? this.localeDate.format("MMM") : null
    },
    day() {
      return this.localeDate ? this.localeDate.format("D") : null
    },
    localeDate() {
      if (!this.date) return null
      return moment(String(this.date)).locale(this.$language.current)
    },
  },
}
</script>
<style lang="sass">
@import '@/assets/sass/_variables.sass'
@import '@/assets/sass/_responsive.sass'

.level-date
  text-align: center
  display: flex
  flex-direction: column
  justify-items: flex-start
  margin-right: 1.5rem
  .year
    font-size: .85rem
    font-weight: 300
    margin: 0
  .month
    margin: 0
  .day
    font-size: 1.5rem
    white-space: nowrap
    margin: 0
</style>

const storageName = [window.location.hostname, "ataquilla-gtm2404"].join(".")
const EventCategory = "Evento"
import _ from "lodash"

export default {
  namespaced: true,
  state: {
    initialized: false,
    // @see EventDetail.vue
    checkoutType: null,
  },
  getters: {
    getCheckoutType: (state) => {
      return state.checkoutType ? state.checkoutType : "ch_corto"
    },
  },
  actions: {
    load({ commit }) {
      commit("loadStorage")
    },
    reset() {
      return this._vm.$gtm.trackEvent({ ecommerce: null })
    },
    /* eslint-disable no-unused-vars */
    track({ store }, payload) {
      return this._vm.$gtm.trackEvent(payload)
    },
    saveState({ state }) {
      localStorage.setItem(
        storageName,
        JSON.stringify({
          checkoutType: state.checkoutType,
        })
      )
    },
    setCheckoutType({ dispatch, commit }, checkoutType) {
      commit("setCheckoutType", checkoutType)
      dispatch("saveState")
    },
    // Steps

    /* Detalle de evento */
    async viewCart({ getters, dispatch }, payload) {
      if (!this._vm.$env.gtm2404_id || !this._vm.$gtm.enabled()) {
        return
      }
      await dispatch("reset")
      await dispatch("track", {
        event: "view_cart",
        ecommerce: {
          currency: "EUR",
          items: [
            {
              item_name: payload.event.name,
              item_id: payload.event.lookup_ref,
              price: "",
              item_brand: payload.event.place.name,
              item_category: EventCategory,
              item_category2: "-",
              item_category3: "-",
              item_category4: "-",
              item_category5: "-",
              item_variant: payload.event.place.municipality,
              is_in_stock: "yes",
              quantity: 1,
              currency: "EUR",
              date_event: "-",
              zone_event: "-",
            },
          ],
        },
      })
    },
    /* Detalle de sesión */
    async beginCheckout({ getters, dispatch }, payload) {
      if (!this._vm.$env.gtm2404_id || !this._vm.$gtm.enabled()) {
        return
      }

      const session = payload.session

      await dispatch("reset")
      await dispatch("track", {
        event: "begin_checkout",
        eventCategory: "Evento",
        ecommerce: {
          currency: "EUR",
          items: [
            {
              item_name: session.event.name,
              item_id: session.event.lookup_ref,
              price: "",
              item_brand: session.event.place.name,
              item_category: EventCategory,
              item_category2: "-",
              item_category3: "-",
              item_category4: "-",
              item_category5: "-",
              item_variant: session.event.place.municipality,
              is_in_stock: "yes",
              quantity: 1,
              currency: "EUR",
              date_event: session.start_datetime,
              zone_event: "-",
            },
          ],
        },
      })
    },
    /* Detalle de zona */
    async checkoutZone({ getters, dispatch }, payload) {
      if (!this._vm.$env.gtm2404_id || !this._vm.$gtm.enabled()) {
        return
      }

      const zone = payload.zone

      await dispatch("reset")
      await dispatch("track", {
        event: "checkout_zone",
        eventCategory: "Evento",
        ecommerce: {
          currency: "EUR",
          items: [
            {
              item_name: zone.session.event.name,
              item_id: zone.session.event.lookup_ref,
              price: zone.ticket_price,
              item_brand: zone.session.event.place.name,
              item_category: EventCategory,
              item_category2: "-",
              item_category3: "-",
              item_category4: "-",
              item_category5: "-",
              item_variant: zone.session.event.place.municipality,
              is_in_stock: "yes",
              quantity: 1,
              currency: "EUR",
              date_event: zone.session.start_datetime,
              zone_event: zone.name,
            },
          ],
        },
      })
    },
    /* Añadir butaca al carrito */
    async checkoutZoneAddToCart({ getters, dispatch }, payload) {
      if (!this._vm.$env.gtm2404_id || !this._vm.$gtm.enabled()) {
        return
      }

      const zone = payload.zone
      const items = []

      // Se añade un item por cada cantidad
      // y la cantidad siempre se pone como 1
      for (let i = 0; i < payload.lines.length; i++) {
        // Buscar el precio
        let price = null

        // Si no tiene concesión, el precio es el de la zona
        const line = payload.lines[i]

        if (!line.concessionId) {
          price = zone.total_price
        } else {
          const matches = zone.concessions.filter((c) => {
            return c.id == line.concessionId
          })

          if (matches.length > 0) {
            const concession = matches[0]
            price = concession.total_price
          }

          if (price == null) {
            price = zone.total_price
          }
        }

        items.push({
          item_name: zone.session.event.name,
          item_id: zone.session.event.lookup_ref,
          price: price,
          item_brand: zone.session.event.place.name,
          item_category: EventCategory,
          item_category2: "-",
          item_category3: "-",
          item_category4: "-",
          item_category5: "-",
          item_variant: zone.session.event.place.municipality,
          is_in_stock: "yes",
          quantity: 1,
          currency: "EUR",
          date_event: zone.session.start_datetime,
          zone_event: zone.name,
        })
      }

      await dispatch("reset")
      await dispatch("track", {
        event: "checkout_zone_add_to_cart",
        eventCategory: "Evento",
        ecommerce: {
          currency: "EUR",
          items: items,
        },
      })
    },
    /* Quitar butaca del carrito */
    async removeFromCart({ getters, dispatch }, payload) {
      if (!this._vm.$env.gtm2404_id || !this._vm.$gtm.enabled()) {
        return
      }

      const zone = payload.zone

      await dispatch("reset")
      await dispatch("track", {
        event: "remove_from_cart",
        eventCategory: "Evento",
        ecommerce: {
          currency: "EUR",
          items: [
            {
              item_name: zone.session.event.name,
              item_id: zone.session.event.lookup_ref,
              price: payload.price,
              item_brand: zone.session.event.place.name,
              item_category: EventCategory,
              item_category2: "-",
              item_category3: "-",
              item_category4: "-",
              item_category5: "-",
              item_variant: zone.session.event.place.municipality,
              is_in_stock: "yes",
              quantity: 1,
              currency: "EUR",
              date_event: zone.session.start_datetime,
              zone_event: zone.name,
            },
          ],
        },
      })
    },
    /**
     * Esto se dispara al crear un pedido
     * tras pulsar el botón "Comprar entradas"
     */
    async addShippingInfo({ getters, dispatch }, payload) {
      if (!this._vm.$env.gtm2404_id || !this._vm.$gtm.enabled()) {
        return
      }

      const order = payload.order
      const orderLines = []

      // Un pedido puede tener tickets de distintas sesiones
      // y el precio nos viene en tickets_payment
      // asíq ue no veo como evitar hacer esto manualmente
      for (const ticketIndex in order.tickets) {
        const ticket = order.tickets[ticketIndex]
        // El precio está en "tickets_payment"
        let ticket_price = ""

        for (const paymentIndex in order.tickets_payment) {
          const payment = order.tickets_payment[paymentIndex]
          if (payment.id == ticket.id) {
            ticket_price = payment.total
            break
          }
        }

        orderLines.push({
          item_name: order.event.name,
          item_id: order.event.lookup_ref,
          price: ticket_price,
          item_brand: order.event.place.name,
          item_category: EventCategory,
          item_category2: "-",
          item_category3: "-",
          item_category4: "-",
          item_category5: "-",
          item_variant: order.event.place.municipality,
          is_in_stock: "yes",
          quantity: 1,
          currency: "EUR",
          date_event: ticket.start_datetime,
          zone_event: ticket.seat.zone_name,
          type_seat:
            ticket && ticket.concession && ticket.concession.name
              ? ticket.concession.name
              : "General",
        })
      }

      await dispatch("reset")
      await dispatch("track", {
        event: "add_shipping_info",
        eventCategory: "Evento",
        ecommerce: {
          currency: "EUR",
          items: orderLines || [],
        },
      })
    },
    /**
     * Se usa al cambiar de concesión en el carrito
     * en lugar de hacer un remove_from_cart
     * y posteriormente un checkout_zone_add_to_cart
     * */
    async checkoutZoneChangeType({ getters, dispatch }, payload) {
      if (!this._vm.$env.gtm2404_id || !this._vm.$gtm.enabled()) {
        return
      }

      /* Función copiada de Cart.vue */
      function getZoneLinesInCart(zoneId) {
        const results = {}
        _.each(payload.cart, (x, index) => {
          if (x && x.sessionZoneId == zoneId) {
            results[index] = x
          }
        })
        return results
      }

      const items = []

      // Creamos una estructura de pedido igual que la real
      // para realizar el envío a GTM
      for (const zoneId in payload.cartZones) {
        const zone = payload.cartZones[zoneId]
        const zoneLines = getZoneLinesInCart(zone.id)

        for (const zoneLineIndex in zoneLines) {
          /**
           * concessionId: 1
           * seatId: null
           * sessionZoneId: 1
           */
          const zoneLine = zoneLines[zoneLineIndex]

          // El precio depende de si tiene concesión o no
          let ticketPrice = zone.ticket_price
          let concessionName = "General"
          let zoneName = zone.name

          if (zoneLine.concessionId) {
            const concessions = zone.concessions.filter(
              (c) => c.id == zoneLine.concessionId
            )
            if (concessions.length > 0) {
              const concession = concessions[0]
              ticketPrice = concession.ticket_price
              concessionName = concession.name
            }
          }

          items.push({
            item_name: payload.session?.event?.name,
            item_id: payload.session?.event?.lookup_ref,
            price: ticketPrice,
            item_brand: payload.session?.event?.place?.name,
            item_category: "Evento",
            item_category2: "-",
            item_category3: "-",
            item_category4: "-",
            item_category5: "-",
            item_variant: payload.session?.event?.place?.municipality,
            is_in_stock: "yes",
            quantity: 1,
            currency: "EUR",
            /* Podría no coincidir si modifican un item de otra sesión */
            date_event: payload.session.start_datetime,
            zone_event: zoneName,
            type_seat: concessionName,
          })
        }
      }

      await dispatch("reset")
      await dispatch("track", {
        event: "checkout_zone_change_type",
        eventCategory: "Evento",
        ecommerce: {
          currency: "EUR",
          items: items,
        },
      })
    },
    /* Confirmar pedido */
    async addPaymentInfo({ getters, dispatch }, payload) {
      if (!this._vm.$env.gtm2404_id || !this._vm.$gtm.enabled()) {
        return
      }

      const order = payload.order
      const lines = []

      // Un pedido puede tener tickets de distintas sesiones
      // y el precio nos viene en tickets_payment
      // así que no veo como evitar hacer esto manualmente
      for (const ticketIndex in order.tickets) {
        const ticket = order.tickets[ticketIndex]
        // El precio está en "tickets_payment"
        let ticket_price = ""

        for (const paymentIndex in order.tickets_payment) {
          const payment = order.tickets_payment[paymentIndex]
          if (payment.id == ticket.id) {
            ticket_price = payment.total
            break
          }
        }

        lines.push({
          item_name: order.event.name,
          item_id: order.event.lookup_ref,
          price: ticket_price,
          item_brand: order.event.place.name,
          item_category: EventCategory,
          item_category2: "-",
          item_category3: "-",
          item_category4: "-",
          item_category5: "-",
          item_variant: order.event.place.municipality,
          is_in_stock: "yes",
          quantity: 1,
          currency: "EUR",
          date_event: ticket.start_datetime,
          zone_event: ticket.seat.zone_name,
          type_seat:
            ticket && ticket.concession && ticket.concession.name
              ? ticket.concession.name
              : "General",
        })
      }

      await dispatch("reset")
      await dispatch("track", {
        event: "add_payment_info",
        eventCategory: "Evento",
        ecommerce: {
          currency: "EUR",
          items: lines,
        },
      })
    },
    /* Pedido finalizado */
    async purchase({ getters, dispatch }, payload) {
      if (!this._vm.$env.gtm2404_id || !this._vm.$gtm.enabled()) {
        return
      }

      const order = payload.order

      // Un pedido puede tener tickets de distintas sesiones
      // y el precio nos viene en tickets_payment
      // asíq ue no veo como evitar hacer esto manualmente
      const items = []

      for (const ticketIndex in order.tickets) {
        const ticket = order.tickets[ticketIndex]
        // El precio está en "tickets_payment"
        let ticket_price = ""

        for (const paymentIndex in order.tickets_payment) {
          const payment = order.tickets_payment[paymentIndex]
          if (payment.id == ticket.id) {
            ticket_price = payment.total
            break
          }
        }

        items.push({
          item_name: order.event.name,
          item_id: order.event.lookup_ref,
          price: ticket_price,
          item_brand: order.event.place.name,
          item_category: EventCategory,
          item_category2: "-",
          item_category3: "-",
          item_category4: "-",
          item_category5: "-",
          item_variant: order.event.place.municipality,
          is_in_stock: "yes",
          quantity: 1,
          currency: "EUR",
          date_event: ticket.start_datetime,
          zone_event: ticket.seat.zone_name,
          type_seat:
            ticket && ticket.concession && ticket.concession.name
              ? ticket.concession.name
              : "General",
        })
      }

      let total_tax = 0

      for (const paymentIndex in order.tickets_payment) {
        const payment = order.tickets_payment[paymentIndex]
        total_tax += Number(payment.tax)
      }

      await dispatch("reset")
      await dispatch("track", {
        event: "purchase",
        ecommerce: {
          payment_type: order.payment_method ? order.payment_method.name : "",
          transaction_id: order.lookup_ref,
          affiliation: "Tienda Online",
          value: order.total_amount,
          tax: total_tax,
          shipping: 0,
          currency: "EUR",
          coupon: order["discount_coupon"] ? order.discount_coupon.code : "",
          import_coupon: "",
          gift: "no",
          hashed_id: order["hashedb64_email"],
          hashed_id_256: order["hashed256_email"],
          eventCategory: "Evento",
          items: items,
        },
      })
    },
  },
  mutations: {
    setCheckoutType(state, checkoutType) {
      state.checkoutType = checkoutType
    },
    loadStorage(state) {
      const storageData = localStorage.getItem(storageName)
      if (!storageData) return
      try {
        let data = JSON.parse(storageData)
        state.checkoutType = data.checkoutType
      } catch (error) {
        return
      }
      state.initialized = true
    },
  },
}

export default {
  props: [
    "name",
    "disabled",
    "errors",
    "label",
    "required",
    "type",
    "value",
    "placeholder",
    "autocomplete",
    "focus",
  ],
  mounted() {
    this.model = this.value
  },
  data() {
    return {
      model: null,
      hasError: false,
    }
  },
  methods: {
    input() {
      this.hasError = false
      this.$emit("input", this.model)
    },
    setFocus() {
      if (!this.$refs[this.name]) return
      this.$refs[this.name].focus
        ? this.$refs[this.name].focus()
        : this.$refs[this.name].$el.focus()
    },
  },
  computed: {
    status() {
      return this.hasError ? "is-danger" : ""
    },
    message() {
      if (!this.hasError) return ""
      return this.errors
    },
  },
  watch: {
    value() {
      this.model = this.value
    },
    errors: {
      handler(errors) {
        this.hasError = errors && errors.length > 0
      },
      deep: true,
    },
  },
}

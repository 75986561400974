<template>
  <div class="input-number">
    <button
      class="button has-text-primary icon is-medium"
      :disabled="decreaseDisabled"
      @click="decrease"
    >
      <i class="fa fa-minus"></i>
    </button>
    <div class="has-text-secondary value">{{ value }}</div>
    <button
      class="button has-text-primary icon"
      :disabled="increaseDisabled"
      @click="increase"
    >
      <i class="fa fa-plus"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "InputNumber",
  props: ["max", "value"],
  data() {
    return {
      model: null,
    }
  },
  computed: {
    decreaseDisabled() {
      return !this.model
    },
    increaseDisabled() {
      return this.model == this.max
    },
  },
  methods: {
    decrease() {
      this.model -= 1
    },
    increase() {
      this.model += 1
    },
  },
  watch: {
    value() {
      this.model = this.value
    },
    model() {
      this.$emit("input", this.model)
    },
  },
}
</script>
<style lang="sass">
@import '@/assets/sass/_variables.sass'

.input-number
  display: flex
  align-items: center
  justify-content: space-between
  min-width: 7rem
  font-size: 1.2rem
  font-weight: $weight-semibold
  color: $grey-dark
  .value
    padding: 0 .25rem
  .button
    cursor: pointer
    border: none
    border-radius: 50%
    background: transparent
    padding: 0
    font-size: 24px
    &.is-black.is-inverted:hover
      background: transparent
</style>

<template>
  <div class="default-layout" :class="envClass">
    <nav class="navbar is-primary" role="navigation" aria-label="main navigation">
      <div class="navbar-container">
        <div class="navbar-start">
          <slot name="navbar-start" />
        </div>
        <div class="navbar-brand">
          <slot v-if="order" name="navbar-brand" />
          <a v-if="!order" class="navbar-item" :href="$theme().href">
            <img :src="$theme().image" :alt="$theme().name" />
          </a>
        </div>
        <div class="navbar-end">
          <slot name="navbar-end" />
          <template v-if="selectableLanguages.length > 1">
            <b-select
              v-model="$language.current"
              class="field ml-1 navbar-lang-selector"
            >
              <option
                v-for="language in selectableLanguages"
                :key="language"
                :value="language"
              >
                {{ language | uppercase }}
              </option>
            </b-select>
          </template>
        </div>
      </div>
    </nav>
    <div class="page-container">
      <div :class="['page', `page-${$route.name}`]">
        <div :class="[{ container: !marginless }, { 'px-0': marginless }]">
          <div v-if="isLoading" class="loading-container">
            <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
          </div>
          <slot v-if="!isLoading" name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import filters from "@/filters"

export default {
  name: "PageDefault",
  filters: filters,
  props: ["marginless", "isLoading", "order"],
  computed: {
    selectableLanguages() {
      const langs = Object.keys(this.$language.available) || []
      return langs.filter((l) => {
        return (this.$env.selectable_languages || []).includes(l)
      })
    },
    envClass() {
      let name = this.$env.env_name

      if (!name || name === "undefined") {
        name = "localhost"
      }

      const r = {}
      r[`env-${name}`] = true

      if (this.$route.query.t && this.$route.query.t == "clicentradas") {
        r["theme-clicentradas"] = true
      }

      return r
    },
  },
  watch: {
    "$language.current": {
      handler() {
        this.$store.commit("i18n/setLanguage", this.$language.current)
        // Force refresh so user sees updated language from db too
        location.reload()
      },
    },
  },
}
</script>
<style lang="sass">
@import '@/assets/sass/_variables.sass'
@import '@/assets/sass/_responsive.sass'

.default-layout
  display: flex
  flex-direction: column
  height: 100vh
  overflow: hidden

.navbar
  -webkit-box-shadow: 0 2px 3px rgba(19, 19, 17, 0.05), 0 0 0 1px rgba(19, 19, 17, 0.05)
  box-shadow: 0 2px 3px rgba(19, 19, 17, 0.05), 0 0 0 1px rgba(19, 19, 17, 0.05)
  color: $white
  z-index: 0 !important
  .navbar-container
    display: flex
    align-items: center
    margin: 0 auto
    width: 100%
    height: 100%
    max-width: $content-max-width
    padding: 0 .5rem
  .navbar-start
    flex-shrink: 1
  .navbar-brand
    flex-grow: 1
    .navbar-item
      padding: 0 .5rem
  .navbar-end
    flex-shrink: 1
    padding-left: 1rem
    display: flex
    align-items: center
    @include until($desktop)
      margin-right: .5rem

  .navbar-item
    padding: 0

  .navbar-lang-selector
    select
      background-color: $primary
      border: none
      color: white
      &:hover
        text-decoration: underline

    .select:not(.is-multiple):not(.is-loading)::after
      border-color: white

.page-container
  width: 100%
  margin: .5rem auto
  overflow-y: auto
  overflow-x: hidden

.sticky-footer
    background: $primary-light
    color: $white
    width: 100%
    position: absolute
    bottom: 0
    flex-shrink: 1
    -webkit-box-shadow: 0 2px 3px rgba(59, 59, 57, 0.1), 0 0 0 1px rgba(59, 59, 57, 0.1)
    box-shadow: 0 2px 3px rgba(59, 59, 57, 0.1), 0 0 0 1px rgba(59, 59, 57, 0.1)
    z-index: 50
    .content
      display: flex
      justify-content: center
      align-items: center
      @include from($tablet)
        padding: 1rem 0

      @include until($tablet)
        padding: .75rem 0
</style>

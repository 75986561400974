"use strict"
import Vue from "vue"
import Vuex from "vuex"

import i18n from "./i18n"
import ui from "./ui"
import order from "./order"
import multipassOrder from "./multipass-order"
import shoppingCart from "./shopping-cart"
import multipassCart from "./multipass-cart"
import gtm from "./gtm"
import gtm2404 from "./gtm2404"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    i18n: i18n,
    gtm: gtm,
    gtm2404: gtm2404,
    multipassCart: multipassCart,
    multipassOrder: multipassOrder,
    order: order,
    shoppingCart: shoppingCart,
    ui: ui,
  },
})

export default store
